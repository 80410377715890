let Opener = document.querySelectorAll('.open-description-js')
let Closer = document.querySelectorAll('.close-description-js')
let Modal
let Transcription


if(Opener) {
	Opener.forEach((el)=> {
		el.onclick = function() {
			Modal = this.closest('.video-modal')
			Transcription = Modal.querySelector('.transcription')
			Transcription.classList.add('opened')
			this.classList.add('hidden')
			this.setAttribute('tabindex','-1')
		}
	});
}

if(Closer) {
	Closer.forEach((el)=> {
		el.onclick = function() {
			Modal = this.closest('.video-modal')
			Transcription = Modal.querySelector('.transcription')
			Opener = Modal.querySelector('.open-description-js')
			Transcription.classList.remove('opened')
			Opener.classList.remove('hidden')
			Opener.removeAttribute('tabindex')
		}
	});
}



