var scrollPosition = window.scrollY;
var logoContainer = document.getElementsByClassName('js-sticky-header')[0];
const body = document.querySelector('body')

const myModal  = document.getElementById('mainSearchPanel');
var modal = new bootstrap.Modal(myModal)

window.addEventListener('scroll', function() {

    scrollPosition = window.scrollY;

    if (scrollPosition >= 1) {
        logoContainer.classList.add('sticky-header');
    } else {
        logoContainer.classList.remove('sticky-header');
		  body.classList.remove("overflow-visible")
		  body.classList.remove("modal-open")
		  modal.hide();
    }
});
