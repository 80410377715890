//import css
import "./scss/main.scss";

//import components js
import "./components/backtotop/backtotop.js";
import "./components/banner/banner.js";
import "./components/breadcrumbs/breadcrumbs.js";
import "./components/button-video/button-video.js";
import "./components/button/button.js";
import "./components/card-big/card-big.js";
import "./components/card-horizontal/card-horizontal.js";
import "./components/card-icon/card-icon.js";
import "./components/card-image-overlay/card-image-overlay.js";
import "./components/card-media/card-media.js";
import "./components/card-news/card-news.js";
import "./components/card-podcast/card-podcast.js";
import "./components/carousel-card-2/carousel-card-2.js";
import "./components/carousel-card-3-horizontal/carousel-card-3-horizontal.js";
import "./components/carousel-cards-3/carousel-cards-3.js";
import "./components/carousel-cards-3b/carousel-cards-3b.js";
import "./components/carousel-cards-4/carousel-cards-4.js";
import "./components/carousel-podcast/carousel-podcast.js";
import "./components/chip/chip.js";
import "./components/cols-2/cols-2.js";
import "./components/contacts/contacts.js";
import "./components/crosslink/crosslink.js";
import "./components/dropdown-menu/dropdown-menu.js";
import "./components/dropdown-panel/dropdown-panel.js";
import "./components/feedback/feedback.js";
import "./components/figure/figure.js";
import "./components/filters/filters.js";
import "./components/footer/footer.js";
import "./components/footnotes/footnotes.js";
import "./components/free-text/free-text.js";
import "./components/grid-card-1-plus-2a/grid-card-1-plus-2a.js";
import "./components/grid-card-1-plus-4-plus-4/grid-card-1-plus-4-plus-4.js";
import "./components/grid-card-1-plus-4/grid-card-1-plus-4.js";
import "./components/grid-card-2-plus-2/grid-card-2-plus-2.js";
import "./components/grid-card-2-plus-3/grid-card-2-plus-3.js";
import "./components/grid-card-3-plus-4/grid-card-3-plus-4.js";
import "./components/grid-card-cta-plus-3/grid-card-cta-plus-3.js";
import "./components/header-center/header-center.js";
import "./components/header-nav/header-nav.js";
import "./components/header-slim/header-slim.js";
import "./components/header/header.js";
import "./components/hero-full-search/hero-full-search.js";
import "./components/hero-podcast/hero-podcast.js";
import "./components/hero-search/hero-search.js";
import "./components/hero-splide/hero-splide.js";
import "./components/hero-video/hero-video.js";
import "./components/horizontal-media/horizontal-media.js";
import "./components/image/image.js";
import "./components/info/info.js";
import "./components/map/map.js";
import "./components/modal-contact/modal-contact.js";
import "./components/modal-faq/modal-faq.js";
import "./components/modal/modal.js";
import "./components/navscroll/navscroll.js";
import "./components/pagination/pagination.js";
import "./components/pill/pill.js";
import "./components/read-more/read-more.js";
import "./components/search-media/search-media.js";
import "./components/search-panel/search-panel.js";
import "./components/search-results/search-results.js";
import "./components/sidebar-sections/sidebar-sections.js";
import "./components/simple-cta/simple-cta.js";
import "./components/skiplinks/skiplinks.js";
import "./components/spinner/spinner.js";
import "./components/splide-carousel/splide-carousel.js";
import "./components/splide-slide/splide-slide.js";
import "./components/tab-pane/tab-pane.js";
import "./components/table-doc/table-doc.js";
import "./components/tabs/tabs.js";
import "./components/tags/tags.js";
import "./components/text-block/text-block.js";
import "./components/title-text-cta/title-text-cta.js";
import "./components/title-text/title-text.js";
import "./components/video-inline/video-inline.js";
import "./components/video-youtube/video-youtube.js";
import "./components/video/video.js";

import "./js/browser-detect";
import "./js/lazyload";


document.addEventListener("DOMContentLoaded", function() {
	bootstrap.loadFonts('fonts');
});
