const resetSearchBtn = document.getElementById('resetSearch');
const inputSearch = document.getElementById('searchInputHero');
const searchButton = document.getElementById('searchButton');

if (resetSearchBtn) {
	resetSearchBtn.addEventListener('click',
		function () {
			this.classList.add('d-none');
			searchButton.classList.remove('d-none');
			inputSearch.value = null;
			inputSearch.focus();
		}
	);
}
