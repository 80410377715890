let opener = document.querySelector('.js-open-search-panel');
let body = document.querySelector('body');
let closer = document.querySelector('.js-close-search-panel');

opener.onclick = function() {
	body.classList.add('overflow-visible');
}

closer.onclick = function() {
	body.classList.remove('overflow-visible');
}
