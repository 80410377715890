
import Splide from '@splidejs/splide';

document.addEventListener( 'DOMContentLoaded', function () {

	//check if heroSplide ecists in page
	const heroSplide = document.getElementsByClassName('hero-splide')

	//if so activactes both carousels
	if (heroSplide[0]) {
		const main = new Splide( '.main-splide', {
			pagination:false,
			i18n: {
				prev: 'Vai alla slide precedente',
				next: 'Vai alla slide successiva',
				pageX: "Vai alla pagina %s",
				first: "Vai alla prima slide",
				last: "Vai all'ultima slide",
				slideX: 'Vai alla slide %s',
				play: 'Attiva autoplay',
				pause: 'Pausa autoplay',
				slideLabel: '%s di %s',
				carousel: 'Carousel di immagini'
		  }
		});
		const thumb = new Splide( '.thumb-splide', {
			isNavigation: true,
			pagination:false,
			type: "loop",
			perPage: 6,
			gap : "16px",
			padding: { left: '0', right: '0' },
			autoWidth: true,
			width:690,
			i18n: {
				prev: 'Vai alla slide precedente',
				next: 'Vai alla slide successiva',
				pageX: "Vai alla pagina %s",
				first: "Vai alla prima slide",
				last: "Vai all'ultima slide",
		  },
			breakpoints: {
				767: {
					type: "loop",
					pagination: true,
					perPage: 1,
					gap : "10px",
					padding: { left: '90px', right: '90px' },
					drag: true,
					autoWidth:false,
					width:false
				},
				991: {
					type: "loop",
					perPage: 4,
					pagination: true,
					padding: { left: '40px', right: '40px' },
					drag: true,
					autoWidth:false,
					width:false
				},
				1300: {
					type: "loop",
					perPage: 6,
					pagination: true,
					padding: { left: '40px', right: '40px' },
					drag: true,
					autoWidth:false,
					width:false
				},
			}
		});
		main.sync( thumb );
		main.mount();
		thumb.mount()
	}

})
