let drop = document.querySelector(".dropdown-tab-wrapper");
let buttons

let textContainer
let content

if(drop) {
	buttons = drop.querySelectorAll(".dropdown-item");
	buttons.forEach((element) => {
		element.onclick = function(){
			textContainer = element.closest(".dropdown-tab-wrapper")
			textContainer = textContainer.querySelector(".dopdown-tab").querySelector('span')
			content = element.innerHTML
			textContainer.innerHTML = content
		}
	})
}

