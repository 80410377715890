let videoModal = document.querySelectorAll('.video-modal');
let id
let myModal
let video
let IDvideo
let videoEl
let Player

if (videoModal) {
	videoModal.forEach((el) => {
		id = el.getAttribute('id');
		myModal = document.getElementById(id)
		myModal.addEventListener('hidden.bs.modal', function (event) {
			video = this.querySelector('.video-js')
			IDvideo = video.getAttribute('id')
			videoEl = document.getElementById(IDvideo)
			Player = bootstrap.VideoPlayer.getOrCreateInstance(videoEl).player
			Player.pause()
		})
	})
}

